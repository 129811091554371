var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "cog-icon cog-i-file",
      class: { "cog-i-file--dark-text": _vm.fileType.darkText },
      attrs: { viewBox: "0 0 32 32" }
    },
    [
      _c("g", { staticClass: "cog-i-file__paper" }, [
        _c("polygon", {
          attrs: { points: "4 31.5 4 0.5 22.25 0.5 28 6.06 28 31.5 4 31.5" }
        }),
        _vm._v(" "),
        _c("polygon", {
          attrs: {
            points:
              "4.01 0.46 4.01 31.5 27.97 31.5 27.97 5.99 22.28 0.51 4.01 0.46"
          }
        }),
        _vm._v(" "),
        _c("polyline", { attrs: { points: "27.98 6.34 21.94 6.34 21.94 0.3" } })
      ]),
      _vm._v(" "),
      _c("rect", {
        staticClass: "cog-i-file__color",
        attrs: { fill: _vm.fileType.color, x: "6", y: "23.09" }
      }),
      _vm._v(" "),
      _c(
        "text",
        { staticClass: "cog-i-file__extension", attrs: { x: "16", y: "29.7" } },
        [_vm._v(_vm._s(_vm.fileType.extension))]
      ),
      _vm._v(" "),
      _c("line", {
        staticClass: "cog-i-file__shadow",
        attrs: { x1: "6.77", y1: "31.51", x2: "25.25", y2: "31.51" }
      }),
      _vm._v(" "),
      _vm.fileType.name === "text"
        ? _c("g", { staticClass: "cog-i-file__format-symbol" }, [
            _c("line", {
              attrs: { x1: "9.13", y1: "9.26", x2: "22.86", y2: "9.26" }
            }),
            _vm._v(" "),
            _c("line", {
              attrs: { x1: "9.13", y1: "12.33", x2: "22.86", y2: "12.33" }
            }),
            _vm._v(" "),
            _c("line", {
              attrs: { x1: "9.13", y1: "15.4", x2: "22.86", y2: "15.4" }
            }),
            _vm._v(" "),
            _c("line", {
              attrs: { x1: "9.13", y1: "18.47", x2: "22.86", y2: "18.47" }
            })
          ])
        : _vm.fileType.name === "document"
        ? _c("g", { staticClass: "cog-i-file__format-symbol" }, [
            _c("path", {
              attrs: {
                d:
                  "M12.3,11.45H10.45l-.33.76a1.14,1.14,0,0,0-.12.42.26.26,0,0,0,.11.19,1,1,0,0,0,.45.11v.13H9.05v-.13a1,1,0,0,0,.39-.14,2.29,2.29,0,0,0,.4-.69l1.68-3.94h.13l1.66,4a1.81,1.81,0,0,0,.37.62.77.77,0,0,0,.46.17v.13H12.25v-.13a.7.7,0,0,0,.38-.1.23.23,0,0,0,.1-.2,1.37,1.37,0,0,0-.14-.49Zm-.1-.26-.81-1.93-.83,1.93Z"
              }
            }),
            _vm._v(" "),
            _c("line", {
              attrs: { x1: "15.1", y1: "9.26", x2: "22.86", y2: "9.26" }
            }),
            _vm._v(" "),
            _c("line", {
              attrs: { x1: "15.1", y1: "12.33", x2: "22.86", y2: "12.33" }
            }),
            _vm._v(" "),
            _c("line", {
              attrs: { x1: "9.13", y1: "15.4", x2: "22.86", y2: "15.4" }
            }),
            _vm._v(" "),
            _c("line", {
              attrs: { x1: "9.13", y1: "18.47", x2: "22.86", y2: "18.47" }
            })
          ])
        : _vm.fileType.name === "audio"
        ? _c("g", { staticClass: "cog-i-file__format-symbol" }, [
            _c("path", {
              attrs: {
                transform: "translate(-4 -3)",
                d:
                  "M20.67,11.56l-4,4H14v4h2.66l4,4ZM22,12.89v1.33a3.42,3.42,0,0,1,0,6.67v1.33a4.72,4.72,0,0,0,0-9.33Zm0,2.67v4a2,2,0,0,0,1.33-2A2.06,2.06,0,0,0,22,15.56Z"
              }
            })
          ])
        : _vm.fileType.name === "archive"
        ? _c(
            "g",
            {
              staticClass:
                "cog-i-file__format-symbol cog-i-file__format-symbol--archive"
            },
            [
              _c("rect", { attrs: { x: "9.47", y: "1.02" } }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "11.77", y: "2.3" } }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "9.47", y: "3.58" } }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "11.77", y: "4.86" } }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "9.47", y: "6.14" } }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M9.47,13.1v7.14h4.59V13.1Zm3.2,5.66H10.86V16.94h1.81Z"
                }
              }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "11.77", y: "7.42" } }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "9.47", y: "8.7" } }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "11.77", y: "9.98" } }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "9.47", y: "11.27" } })
            ]
          )
        : _vm.fileType.name === "code"
        ? _c("g", { staticClass: "cog-i-file__format-symbol" }, [
            _c("path", {
              attrs: {
                d:
                  "M13.75,20.16a2.49,2.49,0,0,1-1.81-.58,2.49,2.49,0,0,1-.59-1.85V16.24a1.35,1.35,0,0,0-.27-.91,1.16,1.16,0,0,0-.9-.3H9.81V13.92h.37a1.73,1.73,0,0,0,.54-.07.72.72,0,0,0,.36-.21.71.71,0,0,0,.2-.36,1.92,1.92,0,0,0,.07-.52v-1a3.75,3.75,0,0,1,.13-1.06,1.82,1.82,0,0,1,.43-.76,1.79,1.79,0,0,1,.75-.46,3.21,3.21,0,0,1,1.09-.16h.5v1.1h-.37a2,2,0,0,0-.51.07.94.94,0,0,0-.39.22,1,1,0,0,0-.25.4,1.85,1.85,0,0,0-.09.62v1a3.18,3.18,0,0,1-.07.75,1.46,1.46,0,0,1-.23.52,1,1,0,0,1-.39.33,2,2,0,0,1-.55.16,1.26,1.26,0,0,1,.94.49,2.2,2.2,0,0,1,.3,1.28v1.49a1.87,1.87,0,0,0,.09.63,1,1,0,0,0,.25.41.92.92,0,0,0,.39.21,1.61,1.61,0,0,0,.51.07h.37v1.1Z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M18.24,9.29a2.56,2.56,0,0,1,1.82.58,2.5,2.5,0,0,1,.59,1.86v1a1.32,1.32,0,0,0,.27.91,1.15,1.15,0,0,0,.89.3h.38V15h-.38a2,2,0,0,0-.53.06.9.9,0,0,0-.36.21.8.8,0,0,0-.2.36,2,2,0,0,0-.07.53v1.54a3.8,3.8,0,0,1-.13,1.06,1.85,1.85,0,0,1-.43.75,1.79,1.79,0,0,1-.75.46,3.27,3.27,0,0,1-1.1.16h-.49v-1.1h.37a1.61,1.61,0,0,0,.51-.07.84.84,0,0,0,.39-.22,1,1,0,0,0,.25-.4,1.85,1.85,0,0,0,.09-.62V16.23a3.24,3.24,0,0,1,.07-.75,1.52,1.52,0,0,1,.23-.53,1.08,1.08,0,0,1,.38-.32,2.1,2.1,0,0,1,.55-.15,1.27,1.27,0,0,1-.93-.5,2.22,2.22,0,0,1-.3-1.28v-1a1.93,1.93,0,0,0-.09-.64,1.05,1.05,0,0,0-.25-.41.92.92,0,0,0-.39-.21,1.61,1.61,0,0,0-.51-.07h-.37V9.29Z"
              }
            })
          ])
        : _vm.fileType.name === "data"
        ? _c(
            "g",
            {
              staticClass:
                "cog-i-file__format-symbol cog-i-file__format-symbol--data"
            },
            [
              _c("rect", { attrs: { x: "8.11", y: "16.67" } }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "13.72", y: "16.67" } }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "19.34", y: "16.67" } }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "8.11", y: "13.23" } }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "13.72", y: "13.23" } }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "19.34", y: "13.23" } }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "8.13", y: "9.76" } }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "13.75", y: "9.76" } }),
              _vm._v(" "),
              _c("rect", { attrs: { x: "19.37", y: "9.76" } })
            ]
          )
        : _vm.fileType.name === "markup"
        ? _c("g", { staticClass: "cog-i-file__format-symbol" }, [
            _c("path", {
              attrs: {
                d: "M11.37,18.05,7.42,14.72l3.95-3.32.91.93-2.9,2.38,2.9,2.42Z"
              }
            }),
            _vm._v(" "),
            _c("path", { attrs: { d: "M14.75,19.18H13.36l4.09-9.54h1.4Z" } }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M20.63,11.4l3.95,3.32-3.95,3.33-.91-.92,2.9-2.4-2.9-2.4Z"
              }
            })
          ])
        : _vm.fileType.name === "presentation"
        ? _c("g", { staticClass: "cog-i-file__format-symbol" }, [
            _c("line", {
              attrs: { x1: "17.82", y1: "11.61", x2: "24.4", y2: "11.61" }
            }),
            _vm._v(" "),
            _c("line", {
              attrs: { x1: "17.82", y1: "14.61", x2: "24.4", y2: "14.61" }
            }),
            _vm._v(" "),
            _c("line", {
              attrs: { x1: "17.82", y1: "17.61", x2: "24.4", y2: "17.61" }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: { d: "M12.33,13.86l3.18-1.3a4.22,4.22,0,0,0-3.18-2.14Z" }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M11.33,15.35V10.42a4.23,4.23,0,1,0,4.73,4.19,4.17,4.17,0,0,0-.17-1.13Z"
              }
            })
          ])
        : _vm.fileType.name === "vector"
        ? _c("g", { staticClass: "cog-i-file__format-symbol" }, [
            _c("line", {
              attrs: { x1: "17.44", y1: "11.24", x2: "23.04", y2: "11.24" }
            }),
            _vm._v(" "),
            _c("line", {
              attrs: { x1: "8.48", y1: "11.24", x2: "14.35", y2: "11.24" }
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "cog-i-file__vector-handle-square",
              attrs: { x: "7.96", y: "10.09" }
            }),
            _vm._v(" "),
            _c("rect", {
              staticClass: "cog-i-file__vector-handle-square",
              attrs: { x: "22.04", y: "10.09" }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M23.4,16.71a7.66,7.66,0,0,0-6.26-6.18V9.86H14.39v.71a7.67,7.67,0,0,0-6,6.14H7.58v2.75h2.75V16.71H9.88a6.18,6.18,0,0,1,4.51-4.59v.49h2.75v-.55a6.18,6.18,0,0,1,4.74,4.65h-.46v2.75h2.75V16.71Zm-13.82,2H8.33V17.46H9.58Zm6.82-6.84H15.13V10.6H16.4Zm7,6.84H22.17V17.46h1.25Z"
              }
            })
          ])
        : _vm.fileType.name === "video"
        ? _c("g", { staticClass: "cog-i-file__format-symbol" }, [
            _c("path", {
              attrs: {
                d:
                  "M8.84,9.34v10.9h15.1V9.34ZM11.16,19H9.9V17.7h1.26Zm0-2.37H9.9V15.34h1.26Zm0-2.36H9.9V13h1.26Zm0-2.37H9.9V10.6h1.26ZM20.64,19H12.21V10.58h8.43ZM23,19H21.73V17.7H23Zm0-2.37H21.73V15.34H23Zm0-2.36H21.73V13H23Zm0-2.37H21.73V10.6H23Z"
              }
            })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }